import React, { useEffect } from "react";
import { element } from "prop-types";
import { useAuth } from "./AuthContext";
import { Navigate } from "react-router-dom";
import Loader from "../Loader";
import styled from "styled-components";

const LoaderContainer = styled.div`
    width: 100%;
    padding: 24px;
`;

const RequireAuth = ({ children }) => {
    const { isSignedIn, signIn } = useAuth();

    useEffect(() => {
        let isCleanedUp = false;

        if (!isCleanedUp) {
            // Calling signIn function without argument
            // will result in an attempt to authenticate
            // with previously saved authentication tokens
            signIn();
        }

        return () => {
            isCleanedUp = true;
        };
    }, []);

    if (isSignedIn === null) {
        return (
            <LoaderContainer>
                <Loader width="240px" />
            </LoaderContainer>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/auth" />;
    }

    return children;
};

RequireAuth.propTypes = {
    children: element,
};

export default RequireAuth;
