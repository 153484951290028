import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const Hide = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M9.41421 9.41421C9.77614 9.05228 10 8.55228 10 8C10 6.89543 9.10457 6 8 6C7.44706 6 6.92679 6.14876 6.5 6.5M9.41421 9.41421L11.6094 11.6094M9.41421 9.41421L6.5 6.5M2.91671 5.88696C2.00225 6.99549 1.72245 7.54754 1.5 8C1.69681 8.43171 4 13 8 13C8.64624 13 9.25333 12.8695 9.81705 12.6506M11.6094 11.6094C13.4466 10.1307 14.253 8.48254 14.5 8C14.2675 7.55294 12 3 8 3C6.61882 3 5.37092 3.60801 4.3886 4.3886M11.6094 11.6094L14 14M4.3886 4.3886L2 2M4.3886 4.3886L6.5 6.5"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

Hide.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default Hide;
