import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const SortAsc = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M2.5 4H7.5M2.5 12H13.5M2.5 8H10.5"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

SortAsc.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default SortAsc;
