import React from "react";
import Colors from "../../styles/Colors";
import { number, string } from "prop-types";

const BaseIcon = ({ path, strokeColor = Colors.black, strokeWidth = 1 }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d={path}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

BaseIcon.propTypes = {
    path: string.isRequired,
    strokeColor: string,
    strokeWidth: number,
};

export default BaseIcon;
