import React, { useState } from "react";
import DayExpenseAddFormView from "./DayExpenseAddFormView";

const POST_VARIABLE_EXPENSE_PATH = "/expense/variable";

const currencies = [
    { value: "rub", title: "Рубли, ₽" },
    { value: "usd", title: "Доллары, $" },
];

const categories = [
    "🍕 Готовая еда",
    "🏡 Дом",
    "💊 Здоровье",
    "🕹 Игры",
    "🎬 Кино",
    "🐈 Кошка",
    "✂️ Красота",
    "🚗 Машина",
    "🎓 Обучение",
    "👔 Одежда",
    "🎁 Подарки",
    "🍞 Продукты",
    "🥳 Развлечения",
    "📡 Связь",
    "🚎 Транспорт",
    "🖥 Электроника",
    "❓ Другое",
];

const DayExpenseAddForm = () => {
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        const { target } = e;
        let date = target?.date?.value;
        if (date === "other") {
            date = target?.otherDate?.value;
        }
        const data = {
            expense: parseFloat(target?.expense?.dataset.calculated),
            currency: target?.currency?.value,
            category: target?.category?.value,
            description: target?.description?.value,
            date,
        };
        const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_VARIABLE_EXPENSE_PATH}`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });
            setSubmittingStatus(
                response.ok ? "Успешно добавили" : "Ошибка при добавлении"
            );
        } catch (error) {
            console.error(error);
            setSubmittingStatus("Ошибка при добавлении");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <DayExpenseAddFormView
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
            currencies={currencies}
            categories={categories}
        />
    );
};

export default DayExpenseAddForm;
