import { array, bool, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StyledInput } from "../inputs";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const OptionLabel = styled.label`
    font-size: 16px;
    cursor: pointer;
`;

const RadioInput = styled.input`
    margin-right: 10px;
    cursor: pointer;
`;

const OTHER_OPTION = { title: "Другое", value: "other" };

const getOtherOptionName = (name) =>
    `other${name[0].toUpperCase()}${name.slice(1, name.length)}`;

const RadioSelect = ({ options, name, hasOther }) => {
    if (hasOther) {
        options = [
            ...options,
            { title: OTHER_OPTION.title, value: OTHER_OPTION.value },
        ];
    }

    const [selectedOption, setSelectedOption] = useState(options[0].value);
    const [otherValue, setOtherValue] = useState("");

    const otherInputRef = useRef(null);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        if (e.target.value !== OTHER_OPTION.value) {
            setOtherValue("");
        }
    };

    const handleOtherChange = (e) => {
        setOtherValue(e.target.value);
    };

    useEffect(() => {
        if (selectedOption === OTHER_OPTION.value) {
            otherInputRef.current?.focus();
        }
    }, [selectedOption]);

    return (
        <Container>
            {options.map(({ value, title }) => (
                <OptionLabel key={value}>
                    <RadioInput
                        type="radio"
                        name={name}
                        value={value}
                        checked={selectedOption === value}
                        onChange={handleOptionChange}
                    />
                    {title}
                </OptionLabel>
            ))}

            {selectedOption === OTHER_OPTION.value && (
                <StyledInput
                    name={getOtherOptionName(name)}
                    ref={otherInputRef}
                    type="text"
                    value={otherValue}
                    onChange={handleOtherChange}
                />
            )}
        </Container>
    );
};

RadioSelect.propTypes = {
    options: array.isRequired,
    name: string.isRequired,
    hasOther: bool,
};

export default RadioSelect;
