import React, { useEffect, useRef } from "react";
import { array, bool, func, string } from "prop-types";
import { Button } from "../../Button";
import RadioSelect from "../../RadioSelect";
import Select from "../../Select";
import {
    Actions,
    Field,
    Form,
    Label,
    StyledH1,
} from "../common/styledComponents";
import { NumericWithCalculationInput, StyledInput } from "../../inputs";

const DayExpenseAddFormView = ({
    handleSubmit,
    isSubmitting,
    submittingStatus,
    currencies,
    categories,
}) => {
    const expenseInputRef = useRef(null);
    useEffect(() => {
        expenseInputRef.current?.focus();
    }, []);

    return (
        <>
            <StyledH1>Новый дневной расход</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Field>
                    <Label htmlFor="expense">Сколько</Label>
                    <NumericWithCalculationInput
                        name="expense"
                        id="expense"
                        placeholder="Размер расхода"
                        ref={expenseInputRef}
                    />
                </Field>
                <Field>
                    <Label htmlFor="currency">Валюта</Label>
                    <RadioSelect
                        name="currency"
                        id="currency"
                        options={currencies}
                        hasOther={true}
                    />
                </Field>
                <Field>
                    <Label htmlFor="category">Категория</Label>
                    <Select
                        name="category"
                        id="category"
                        options={categories}
                    />
                </Field>
                <Field>
                    <Label htmlFor="description">Описание</Label>
                    <StyledInput
                        type="text"
                        name="description"
                        id="description"
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Дата</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={[
                            { title: "Сегодня", value: "today" },
                            { title: "Вчера", value: "yesterday" },
                            { title: "Позавчера", value: "dayBeforeYesterday" },
                        ]}
                        hasOther={true}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

DayExpenseAddFormView.propTypes = {
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
    categories: array.isRequired,
    currencies: array.isRequired,
};

export default DayExpenseAddFormView;
