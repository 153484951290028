import React from "react";
import BaseIcon from "./BaseIcon";
import { number, string } from "prop-types";

const ArrowDown = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M13 5.5L8.70711 9.79289C8.31658 10.1834 7.68342 10.1834 7.29289 9.79289L3 5.5"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

ArrowDown.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default ArrowDown;
