import React, { useState } from "react";
import { styled } from "styled-components";
import Navigation from "../components/Navigation";
import DayExpenseList from "../components/DayExpenseList";
import SummaryList from "../components/SummaryList";
import Layout from "../components/Layout";
import Sizes from "../styles/Sizes";
import { getCurrentMonth } from "../helpers/utils";

const Main = styled.main`
    width: 90vw;
    display: flex;
    gap: 32px;
    justify-content: space-between;

    @container body (width <= ${Sizes.widthDesktopNarrow1024}) {
        flex-direction: column;
    }
`;

const Home = () => {
    const [month, setMonth] = useState(getCurrentMonth());
    return (
        <Layout>
            <Navigation month={month} setMonth={setMonth} />
            <Main>
                <DayExpenseList month={month} />
                <SummaryList month={month} />
            </Main>
        </Layout>
    );
};

export default Home;
