import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const Show = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M8 3C12 3 14 7 14.5 8C14 9 12 13 8 13C4 13 2 9 1.5 8C1.97318 7.05364 4 3 7.97126 2.99937M8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.90571 6.90977 6 8 6Z"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

Show.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default Show;
