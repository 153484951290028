import constants from "./constants";

const formatByDigits = (num) => {
    // Use only an integer part of number
    num = parseInt(num);

    let digits = [];
    let sign = "";

    // Save a minus sign if the number is negative
    if (num < 0) {
        sign = "–";
    }

    // Take an absolute value of number
    let s = String(Math.abs(num));

    // Don't do anything if the number contains less than 5 digits
    if (s.length < 5) {
        return sign + s;
    }

    while (s) {
        const left = s.length - 3 < 0 ? 0 : s.length - 3;
        const sliced = s.slice(left, s.length);
        digits = [sliced, ...digits];
        s = s.slice(0, left);
    }

    // Join digit groups with non-breakable space
    return sign + digits.join(String.fromCodePoint(constants.NBSP));
};

const formatSummaryValue = (value, withSign) => {
    // Use only an integer part of number
    value = parseInt(value);

    let sign = "";

    // Fill sign if it is needed
    if (withSign) {
        sign = value >= 0 ? "+" : "–";
    }

    let s = String(Math.abs(value));
    let primary = "";
    let secondary = "";
    let primaryParts = [];
    while (s) {
        const left = s.length - 3 < 0 ? 0 : s.length - 3;
        const sliced = s.slice(left, s.length);
        s = s.slice(0, left);
        if (!secondary) {
            secondary = sliced;
        } else {
            primaryParts = [sliced, ...primaryParts];
        }
    }
    if (primaryParts.length === 0) {
        primary = secondary;
        secondary = "";
    } else {
        primary = primaryParts.join(String.fromCodePoint(constants.NBSP));
    }

    return {
        sign,
        primary,
        secondary,
    };
};

const getCurrentMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}`;
};

const isEmpty = (s) => s === null || s === undefined || !String(s).trim();

const loadFromLocalStorage = (key) => {
    const stringValue = localStorage.getItem(key);
    if (stringValue !== null) {
        // Call JSON.parse to cast a string with boolean value as boolean
        return JSON.parse(stringValue);
    }
    throw new Error("The key doesn't exist or the value is null");
};

const saveToLocalStorage = (key, value) => {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
};

export {
    formatByDigits,
    formatSummaryValue,
    getCurrentMonth,
    isEmpty,
    loadFromLocalStorage,
    saveToLocalStorage,
};
