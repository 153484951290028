import React from "react";
import { string } from "prop-types";
import { useData } from "../../data";
import DayExpenseListView from "./DayExpenseListView";
import { loadFromLocalStorage, saveToLocalStorage } from "../../helpers/utils";

const DATE_SORTING_BY_DESC_LOCAL_STORAGE_KEY =
    "DayExpenseListDateSortingByDesc";

const loadDescending = () => {
    try {
        return loadFromLocalStorage(DATE_SORTING_BY_DESC_LOCAL_STORAGE_KEY);
    } catch (error) {
        // Default value if the load from storage is failed
        return false;
    }
};

const DayExpenseList = ({ month, width }) => {
    const params = new URLSearchParams({ month });
    const data = useData(
        `${process.env.REACT_APP_API_ENDPOINT}/expense/variable?${params}`
    );
    const descending = loadDescending();
    const saveDescending = (value) =>
        saveToLocalStorage(DATE_SORTING_BY_DESC_LOCAL_STORAGE_KEY, value);

    return (
        <DayExpenseListView
            data={data}
            width={width}
            defaultDescending={descending}
            saveDescending={saveDescending}
        />
    );
};

DayExpenseList.propTypes = {
    month: string.isRequired,
    width: string,
};

export default DayExpenseList;
