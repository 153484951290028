import { bool, node } from "prop-types";
import React from "react";
import { styled } from "styled-components";
import Sizes from "../styles/Sizes";

const StyledLayout = styled.div`
    display: flex;
    padding: 40px;
    align-items: flex-start;
    gap: 48px;

    ${({ $column }) => $column && `flex-direction: column;`}

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        gap: 16px;
        flex-direction: column;
    }

    @media (width <= ${Sizes.widthMobileMax}) {
        padding: 16px;
    }
`;

const Layout = ({ column, children }) => (
    <StyledLayout $column={column}>{children}</StyledLayout>
);

Layout.propTypes = {
    column: bool,
    children: node,
};

export default Layout;
