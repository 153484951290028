import { object, string } from "prop-types";
import React from "react";
import styled from "styled-components";

const greenColor = "rgba(14, 204, 0, 0.30)";
const greenAccentColor = "#0ECC00";
const redColor = "rgba(255, 0, 0, 0.30)";
const redAccentColor = "#F00";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    ${({ $haveSameSign }) =>
        $haveSameSign ? "align-items: flex-end;" : "align-items: center;"}
    gap: 1px;
    height: 100%;
`;

const BarWrapper = styled.div`
    position: relative;
    width: 2px;
    ${({ $height }) => $height && `height: ${$height}%;`}
`;

const BarInner = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    ${({ $height, $isCurrent, $isPositive }) => {
        if ($height >= 0) {
            return `top: 0; bottom: 50%; background: ${
                $isPositive
                    ? ($isCurrent
                        ? greenAccentColor
                        : greenColor)
                    : ($isCurrent
                        ? redAccentColor
                        : redColor)
            }`;
        }
        return `top: 50%; bottom: 0; background: ${
            $isPositive
                ? ($isCurrent
                    ? redAccentColor
                    : redColor)
                : ($isCurrent
                    ? greenAccentColor
                    : greenColor)
        }`;
    }}
`;

const Bar = styled.div`
    width: 2px;
    ${({ $height }) => $height && `height: ${$height}%;`}
    ${({ $isPositive, $isCurrent }) =>
        $isPositive
            ? `background: ${$isCurrent ? greenAccentColor : greenColor};`
            : `background: ${$isCurrent ? redAccentColor : redColor};`}
`;

const MonthlyComparison = ({ data, currentMonth }) => {
    const { list, haveSameSign, isPositive } = data;
    if (!haveSameSign) {
        return (
            <Container $haveSameSign={haveSameSign}>
                {Object.entries(list).map(([month, value], index) => (
                    <BarWrapper key={index} $height={Math.abs(value)}>
                        <BarInner
                            $height={value}
                            $isCurrent={currentMonth === month}
                            $isPositive={isPositive}
                        />
                    </BarWrapper>
                ))}
            </Container>
        );
    }
    return (
        <Container $haveSameSign={haveSameSign}>
            {Object.entries(list).map(([month, value], index) => (
                <Bar
                    key={index}
                    $height={value}
                    $isCurrent={currentMonth === month}
                    $isPositive={isPositive}
                />
            ))}
        </Container>
    );
};

MonthlyComparison.propTypes = {
    data: object,
    currentMonth: string,
};

export default MonthlyComparison;
