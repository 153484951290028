import React, { forwardRef, useState } from "react";
import StyledInput from "../StyledInput";
import styled from "styled-components";
import Colors from "../../../styles/Colors";

const Container = styled.div`
    display: flex;
    position: relative;

    & ${StyledInput} {
        width: 100%;
    }
`;

const Precalculated = styled.div`
    position: absolute;
    right: 10px;
    color: ${Colors.greyDisabledText};
    top: calc(50% - 11px);
`;

const NumericWithCalculationInput = forwardRef((props, ref) => {
    const [value, setValue] = useState("");
    const [calculated, setCalculated] = useState(0);

    const handleChange = (e) => {
        let { value } = e.target;

        // Check the value
        if (!/^[0-9.,+-]*$/.test(value)) {
            return;
        }

        setValue(value);

        // Remove a trailing plus or minus from the value
        if (value.endsWith("+") || value.endsWith("-")) {
            value = value.slice(0, value.length - 1);
        }

        // Check that value contains a valid expression
        if (/^\d+([.,]\d+)?([+-]\d+([.,]\d+)?)*$/.test(value)) {
            value = String(value).replaceAll(",", ".");
            setCalculated(eval(value));
        }

        // Use zero as a calculated value if there is no value at all
        if (value.length === 0) {
            setCalculated(0);
        }
    };

    return (
        <Container>
            <StyledInput
                type="text"
                inputMode="tel"
                pattern="^\d+([.,]\d+)?([+-]\d+([.,]\d+)?)*$"
                value={value}
                data-calculated={calculated}
                onChange={handleChange}
                ref={ref}
                {...props}
            />
            <Precalculated>= {calculated}</Precalculated>
        </Container>
    );
});

NumericWithCalculationInput.displayName = "NumericWithCalculationInput";

export default NumericWithCalculationInput;
