import React from "react";
import BaseIcon from "./BaseIcon";
import { number, string } from "prop-types";

const ArrowExpand = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M4 6.5L7.29289 3.20711C7.68342 2.81658 8.31658 2.81658 8.70711 3.20711L12 6.5M12 9.5L8.70711 12.7929C8.31658 13.1834 7.68342 13.1834 7.29289 12.7929L4 9.5"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

ArrowExpand.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default ArrowExpand;
