import styled from "styled-components";
import Colors from "../../../styles/Colors";

const StyledH1 = styled.h1`
    color: ${Colors.greySecondaryText};
`;

const Label = styled.label`
    font-weight: 500;
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    padding-top: 16px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export { Actions, Field, Form, Label, StyledH1 };
