import React, { useState } from "react";
import RevenueAddFormView from "./RevenueAddFormView";

const POST_REVENUE_PATH = "/revenue";

const currencies = [
    { value: "rub", title: "Рубли, ₽" },
    { value: "usd", title: "Доллары, $" },
];

const RevenueAddForm = () => {
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        const { target } = e;
        let date = target?.date?.value;
        if (date === "other") {
            date = target?.otherDate?.value;
        }
        const data = {
            revenue: parseFloat(target?.revenue?.dataset.calculated),
            currency: target?.currency?.value,
            description: target?.description?.value,
            date,
        };
        const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_REVENUE_PATH}`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });
            setSubmittingStatus(
                response.ok ? "Успешно добавили" : "Ошибка при добавлении"
            );
        } catch (error) {
            console.error(error);
            setSubmittingStatus("Ошибка при добавлении");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <RevenueAddFormView
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
            currencies={currencies}
        ></RevenueAddFormView>
    );
};

export default RevenueAddForm;
