import { useEffect, useState } from "react";
import { useAuth } from "./components/auth";

const processNotOk = async (response, signOut) => {
    if (response.status === 403) {
        await signOut();
        return;
    }
    console.error(`${response.status}: ${response.statusText}`);
};

const useData = (url) => {
    const [data, setData] = useState();
    const { signOut } = useAuth();

    useEffect(() => {
        let isCleanedUp = false;

        // Set the not ready data state
        setData(undefined);

        const fetchData = async () => {
            try {
                const response = await fetch(url, { credentials: "include" });

                if (!response.ok) {
                    await processNotOk(response, signOut);
                    return;
                }

                const jsonData = await response.json();
                if (!isCleanedUp) {
                    setData(jsonData);
                }
            } catch (error) {
                console.error(`Can't fetch data: ${error.message}`);
            }
        };

        fetchData();

        return () => {
            isCleanedUp = true;
        };
    }, [url]);
    return data;
};

export { useData };
