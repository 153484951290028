import { css, styled } from "styled-components";
import Colors from "../../styles/Colors";

const ButtonStyled = styled.button`
    padding: 0;
    background-color: unset;
    border: none;
    font-family: Geologica, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: ${Colors.black};
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        cursor: pointer;
    }
    ${({ $view }) => {
        switch ($view) {
            case "link": {
                return css`
                    color: ${Colors.greyDisabledText};
                    svg path {
                        stroke: ${Colors.greyDisabledText};
                    }
                    &:not(:disabled):hover {
                        color: ${Colors.black};
                        svg path {
                            stroke: ${Colors.black};
                        }
                    }
                `;
            }
            case "background": {
                return css`
                    padding: 8px 12px;
                    border-radius: 6px;
                    background-color: ${Colors.grey6PercentBackground};
                    &:not(:disabled):hover {
                        background-color: ${Colors.grey10PercentBackground};
                    }
                    &:active {
                        background-color: ${Colors.grey16PercentBackground};
                    }
                `;
            }
            case "line": {
                return css`
                    padding: 8px 12px;
                    border-radius: 6px;
                    border: solid 1px ${Colors.grey10PercentBackground};
                    &:not(:disabled):hover {
                        border-color: ${Colors.grey16PercentBackground};
                    }
                    &:active {
                        border-color: ${Colors.grey16PercentBackground};
                    }
                `;
            }
        }
    }}
    ${({ $selected }) => $selected && `color: ${Colors.black};`}
    &:disabled {
        cursor: initial;
        color: ${Colors.grey32PercentBackground};
    }
`;

const Animated = styled.span`
    display: flex;
    align-self: center;
    transition: transform 0.2s ease;
    ${({ $isExpanded }) => $isExpanded && `transform: rotateZ(90deg);`}
`;

export { ButtonStyled, Animated };
