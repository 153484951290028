import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const Plus = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M7.99999 2.5V13.5M2.5 8H13.5"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

Plus.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default Plus;
