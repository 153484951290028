import { bool, func } from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { SortAsc, SortDesc } from "../icons";
import Sizes from "../../styles/Sizes";
import Colors from "../../styles/Colors";

const widthBreakpoints = ["640px", "560px", Sizes.widthMobileMax];

const Header = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${Colors.greySecondaryText};
    ${({ $right }) => $right && `justify-content: flex-end;`}
`;

const Cell = styled.div`
    &:nth-child(1) {
        min-width: 128px;

        @container (width <= ${widthBreakpoints[0]}) {
            min-width: 88px;
        }
    }
    &:nth-child(2) {
        min-width: 96px;
    }
    &:nth-child(3) {
        padding-left: 24px;
        flex-grow: 1;
    }
    &:nth-child(4) {
        min-width: 96px;
    }
    &:nth-child(5) {
        min-width: 96px;
    }

    ${(props) =>
        props.onClick &&
        css`
            &:hover {
                cursor: pointer;

                ${Header} {
                    color: ${Colors.black};
                }
            }
        `}
`;

const StyledDayExpenseListItem = styled.div`
    align-self: stretch;
    cursor: default;
    container-type: inline-size;
`;

const FlexWrapper = styled.div`
    position: relative;
    overflow: visible;
    line-height: 20px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -4px;
        right: -4px;
        border-radius: 4px;
        background-color: ${Colors.grey3PercentBackground};
        z-index: -1;
        opacity: 0;
        transition: opacity 0.1s ease;
    }

    &:hover::after {
        opacity: 1;
    }

    @container (width <= ${widthBreakpoints[2]}) {
        padding: 8px 0;

        ${Cell} {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &:nth-child(2) {
                width: calc(33% - 8px);
            }
            &:nth-child(3) {
                order: 1;
                padding: 0;
            }
            &:nth-child(4) {
                width: calc(33% - 8px);
                justify-content: center;
            }
            &:nth-child(5) {
                width: calc(33% - 8px);
                justify-content: flex-end;
            }
        }
    }
`;

const DayExpenseListHeaders = ({
    dateSortingDescending = false,
    onDateSortingChange,
}) => {
    return (
        <StyledDayExpenseListItem>
            <FlexWrapper>
                <Cell onClick={onDateSortingChange}>
                    <Header>
                        дата
                        {dateSortingDescending ? <SortDesc /> : <SortAsc />}
                    </Header>
                </Cell>
                <Cell>
                    <Header $right>на день</Header>
                </Cell>
                <Cell />
                <Cell>
                    <Header $right>расходы</Header>
                </Cell>
                <Cell>
                    <Header $right>сальдо</Header>
                </Cell>
            </FlexWrapper>
        </StyledDayExpenseListItem>
    );
};

DayExpenseListHeaders.propTypes = {
    dateSortingDescending: bool,
    onDateSortingChange: func,
};

export default DayExpenseListHeaders;
