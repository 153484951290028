import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const Google = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M11.4999 4.5C11.1772 4.19681 10.2298 3 7.99992 3C5.77 3 2.99434 4.59563 2.99992 8C3.0055 11.4044 5.71679 13 7.99992 13C10.283 13 12.9517 11.3329 12.9999 8C11.9999 8 9.49992 8 7.99992 8"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

Google.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default Google;
