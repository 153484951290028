import React, { useEffect, useRef } from "react";
import {
    Actions,
    Field,
    Form,
    Label,
    StyledH1,
} from "../common/styledComponents";
import RadioSelect from "../../RadioSelect";
import { array, bool, func, string } from "prop-types";
import { Button } from "../../Button";
import { NumericWithCalculationInput, StyledInput } from "../../inputs";

const PermanentExpenseAddFormView = ({
    handleSubmit,
    isSubmitting,
    submittingStatus,
    currencies,
}) => {
    const focused = useRef(null);
    const setDefaultFocus = () => focused?.current.focus();
    useEffect(setDefaultFocus, []);
    return (
        <>
            <StyledH1>Новый постоянный расход</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Field>
                    <Label htmlFor="expense">Сколько</Label>
                    <NumericWithCalculationInput
                        name="expense"
                        id="expense"
                        placeholder="Размер расхода"
                        ref={focused}
                    />
                </Field>
                <Field>
                    <Label htmlFor="currency">Валюта</Label>
                    <RadioSelect
                        name="currency"
                        id="currency"
                        options={currencies}
                        hasOther={true}
                    />
                </Field>
                <Field>
                    <Label htmlFor="description">Описание</Label>
                    <StyledInput
                        type="text"
                        name="description"
                        id="description"
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Месяц</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={[
                            { title: "Текущий", value: "currentMonth" },
                            { title: "Прошлый", value: "previousMonth" },
                        ]}
                        hasOther={true}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

PermanentExpenseAddFormView.propTypes = {
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
    currencies: array.isRequired,
};

export default PermanentExpenseAddFormView;
