import React, { useState } from "react";
import ReservationAddFormView from "./ReservationAddFormView";

const POST_RESERVATION_PATH = "/reservation";

const ReservationAddForm = () => {
    const [submittingStatus, setSubmittingStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmittingStatus("Добавляем...");
        const { target } = e;
        let date = target?.date?.value;
        if (date === "other") {
            date = target?.otherDate?.value;
        }
        const data = {
            reservation: parseFloat(target?.reservation?.dataset.calculated),
            valueType: target?.valueType?.value,
            date,
        };
        const url = `${process.env.REACT_APP_API_ENDPOINT}${POST_RESERVATION_PATH}`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                credentials: "include",
            });
            setSubmittingStatus(
                response.ok ? "Успешно добавили" : "Ошибка при добавлении"
            );
        } catch (error) {
            console.error(error);
            setSubmittingStatus("Ошибка при добавлении");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ReservationAddFormView
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingStatus={submittingStatus}
        />
    );
};

export default ReservationAddForm;
