import styled from "styled-components";
import Colors from "../../styles/Colors";

const StyledInput = styled.input`
    appearance: none;
    padding: 8px 10px;
    border: 1px solid ${Colors.grey10PercentBackground};
    border-radius: 4px;

    &:hover {
        border-color: ${Colors.grey16PercentBackground};
    }

    &:focus {
        outline: none;
        border-color: ${Colors.grey32PercentBackground};
    }
`;

export default StyledInput;
