import { string } from "prop-types";
import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";

const Self = styled.div`
    background: linear-gradient(
        90deg,
        ${Colors.grey6PercentBackground},
        ${Colors.grey16PercentBackground},
        ${Colors.grey6PercentBackground}
    );
    background-size: 200% 100%;
    border-radius: 6px;
    height: 4px;
    animation: Animation 1s linear infinite;
    ${({ $width }) => $width && `width: ${$width};`};

    @keyframes Animation {
        0% {
            background-position: 0% 0%;
        }
        50% {
            background-position: -100% 0%;
        }
        100% {
            background-position: -200% 0%;
        }
    }
`;

const Loader = ({ width = "40px" }) => {
    return <Self $width={width}></Self>;
};

Loader.propTypes = {
    width: string,
};

export default Loader;
