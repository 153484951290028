import React, { useState } from "react";
import { ButtonStyled, Animated } from "./Styles";
import { ArrowRight } from "../icons";
import { array, bool, element, func, oneOfType, string } from "prop-types";

const LinkExpandable = ({ children, onClick, defaultIsExpanded }) => {
    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

    const handleClick = (e) => {
        setIsExpanded(!isExpanded);
        onClick && onClick(e);
    };

    return (
        <ButtonStyled $type="link" onClick={handleClick}>
            {children}
            <Animated $isExpanded={isExpanded}>
                <ArrowRight />
            </Animated>
        </ButtonStyled>
    );
};

LinkExpandable.propTypes = {
    children: oneOfType([string, element, array]),
    onClick: func,
    defaultIsExpanded: bool,
};

export default LinkExpandable;
