import React, { useEffect, useRef } from "react";
import {
    Actions,
    Field,
    Form,
    Label,
    StyledH1,
} from "../common/styledComponents";
import RadioSelect from "../../RadioSelect";
import { bool, func, string } from "prop-types";
import { Button } from "../../Button";
import { NumericWithCalculationInput } from "../../inputs";

const ReservationAddFormView = ({
    handleSubmit,
    isSubmitting,
    submittingStatus,
}) => {
    const focused = useRef(null);
    const setDefaultFocus = () => focused?.current.focus();
    useEffect(setDefaultFocus, []);
    return (
        <>
            <StyledH1>Новое накопление</StyledH1>
            <Form onSubmit={handleSubmit}>
                <Field>
                    <Label htmlFor="reservation">Сколько</Label>
                    <NumericWithCalculationInput
                        name="reservation"
                        id="reservation"
                        placeholder="Размер накопления"
                        ref={focused}
                    />
                </Field>
                <Field>
                    <Label htmlFor="valueType">Тип значения</Label>
                    <RadioSelect
                        name="valueType"
                        id="valueType"
                        options={[
                            { title: "Абсолютное", value: "absolute" },
                            { title: "Процентное", value: "percent" },
                        ]}
                    />
                </Field>
                <Field>
                    <Label htmlFor="date">Месяц</Label>
                    <RadioSelect
                        name="date"
                        id="date"
                        options={[
                            { title: "Текущий", value: "currentMonth" },
                            { title: "Прошлый", value: "previousMonth" },
                        ]}
                        hasOther={true}
                    />
                </Field>
                <Actions>
                    <Button
                        view="background"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Добавить
                    </Button>
                    {submittingStatus && <div>{submittingStatus}</div>}
                </Actions>
            </Form>
        </>
    );
};

ReservationAddFormView.propTypes = {
    handleSubmit: func.isRequired,
    isSubmitting: bool,
    submittingStatus: string,
};

export default ReservationAddFormView;
