import { array } from "prop-types";
import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { ArrowExpand } from "../icons";

const StyledSelect = styled.select`
    appearance: none;
    padding: 8px 10px;
    background-color: ${Colors.white0};
    border: 1px solid ${Colors.grey10PercentBackground};
    border-radius: 4px;
    width: 100%;

    &:hover {
        cursor: pointer;
        border-color: ${Colors.grey16PercentBackground};
    }

    &:focus {
        outline: none;
        border-color: ${Colors.grey32PercentBackground};
    }
`;

const IconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
`;

const StyledContainer = styled.div`
    position: relative;
`;

const Select = ({ options, ...props }) => {
    return (
        <StyledContainer>
            <StyledSelect {...props}>
                {options.map((o) => (
                    <option key={o} value={o}>
                        {o}
                    </option>
                ))}
            </StyledSelect>
            <IconContainer>
                <ArrowExpand />
            </IconContainer>
        </StyledContainer>
    );
};

Select.propTypes = {
    options: array.isRequired,
};

export default Select;
