import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../components/Layout";
import {
    DayExpenseAddForm,
    PermanentExpenseAddForm,
    ReservationAddForm,
    RevenueAddForm,
} from "../components/forms";
import styled from "styled-components";
import { Button } from "../components/Button";
import { ArrowLeft } from "../components/icons";
import Colors from "../styles/Colors";
import Sizes from "../styles/Sizes";
import { ButtonStyled } from "../components/Button/Styles";

const FormContainer = styled.section`
    align-self: center;
    box-sizing: border-box;
    padding: 0 24px;
    width: 100vw;
    max-width: 640px;
`;

const ItemSelector = styled.div`
    display: flex;
    gap: 24px;
    padding: 24px 0;
    /* border-bottom: solid 1px ${Colors.grey6PercentBackground}; */
    position: relative;

    & ${ButtonStyled}:first-child {
        position: absolute;
        left: -80px;
        top: calc(50% - 16px);
    }

    @media (width <= ${Sizes.widthDesktopNarrow1024}) {
        overflow-x: auto;
        white-space: nowrap;

        & ${ButtonStyled}:first-child {
            position: relative;
            left: inherit;
            top: inherit;
        }
    }
`;

const ITEM_TYPE_PARAM_NAME = "type";

const forms = {
    dayExpense: <DayExpenseAddForm />,
    permanentExpense: <PermanentExpenseAddForm />,
    reservation: <ReservationAddForm />,
    revenue: <RevenueAddForm />,
};

const AddItem = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const itemType = searchParams.get(ITEM_TYPE_PARAM_NAME) || "dayExpense";
    let form = forms[itemType];

    const handleBackClick = () => navigate("/");

    return (
        <Layout column={true}>
            <FormContainer>
                <ItemSelector>
                    <Button
                        view="line"
                        iconLeft={<ArrowLeft />}
                        onClick={handleBackClick}
                    />
                    <Button
                        view="link"
                        onClick={() => navigate("?type=dayExpense")}
                        selected={itemType === "dayExpense"}
                    >
                        Дневной расход
                    </Button>
                    <Button
                        view="link"
                        onClick={() => navigate("?type=permanentExpense")}
                        selected={itemType === "permanentExpense"}
                    >
                        Постоянный расход
                    </Button>
                    <Button
                        view="link"
                        onClick={() => navigate("?type=revenue")}
                        selected={itemType === "revenue"}
                    >
                        Выручка
                    </Button>
                    <Button
                        view="link"
                        onClick={() => navigate("?type=reservation")}
                        selected={itemType === "reservation"}
                    >
                        Накопление
                    </Button>
                </ItemSelector>
                {form}
            </FormContainer>
        </Layout>
    );
};

export default AddItem;
