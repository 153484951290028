import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const ArrowRight = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M6 3L10.2929 7.29289C10.6834 7.68342 10.6834 8.31658 10.2929 8.70711L6 13"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

ArrowRight.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default ArrowRight;
