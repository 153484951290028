import { createGlobalStyle } from "styled-components";
import Colors from "./Colors";

const GlobalStyles = createGlobalStyle`
    :root {
        --mobile-max-width: 430px;
    }

    html, select, input {
        font-family: Geologica, 'Helvetica Neue', sans-serif;
        color: ${Colors.black};
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4;
    }

    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        container-type: inline-size;
        container-name: body;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    h1, h2, h3, h4 {
        font-weight: 600;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
`;

export default GlobalStyles;
