import React from "react";
import { number, string } from "prop-types";
import BaseIcon from "./BaseIcon";

const ArrowLeft = ({ strokeColor, strokeWidth }) => {
    return (
        <BaseIcon
            path="M10 3L5.70711 7.29289C5.31658 7.68342 5.31658 8.31658 5.70711 8.70711L10 13"
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
        />
    );
};

ArrowLeft.propTypes = {
    strokeColor: string,
    strokeWidth: number,
};

export default ArrowLeft;
