import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { styled } from "styled-components";
import { useAuth } from "../components/auth";
import { Button } from "../components/Button";
import { Google } from "../components/icons";
import Layout from "../components/Layout";
import Colors from "../styles/Colors";

const Centralized = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 40px 48px;
    border-radius: 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;

const H1Styled = styled.h1`
    color: ${Colors.greySecondaryText};
`;

const Auth = () => {
    const { isSignedIn, signIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const handleSignedIn = () => navigate(from, { replace: true });

    const login = useGoogleLogin({
        flow: "auth-code",
        client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI,
        scope: "https://www.googleapis.com/auth/spreadsheets",
        include_granted_scopes: true,
        onSuccess: async (tokenResponse) => {
            const { code } = tokenResponse;
            await signIn(code);
            handleSignedIn();
        },
    });

    if (isSignedIn) {
        handleSignedIn();
        return null;
    }

    return (
        <Layout>
            <Centralized>
                <Content>
                    <H1Styled>Расходы</H1Styled>
                    <Button
                        view="background"
                        onClick={login}
                        iconLeft={<Google strokeWidth={1.5} />}
                    >
                        Войти через Google
                    </Button>
                </Content>
            </Centralized>
        </Layout>
    );
};

export default Auth;
