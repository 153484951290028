const Colors = {
    black: "#222222",

    greySecondaryText: "#757575",
    greyDarkBackground: "#D6D6D6",
    greyDisabledText: "#ADADAD",

    grey3PercentBackground: "rgba(0, 0, 0, .03)",
    grey6PercentBackground: "rgba(0, 0, 0, 0.06)",
    grey10PercentBackground: "rgba(0, 0, 0, 0.1)",
    grey16PercentBackground: "rgba(0, 0, 0, 0.16)",
    grey32PercentBackground: "rgba(0, 0, 0, 0.32)",

    green80: "#209644",

    red0: "#F03B36",
    red80: "#DD473B",

    orange0: "#FC7630",
    orange80: "#D46421",

    white0: "#FFFFFF",
    white80: "rgba(255,255,255,.8)",
};

export default Colors;
