import React, { useState } from "react";
import { styled } from "styled-components";
import Colors from "../../styles/Colors";
import { array, number } from "prop-types";
import Tooltip from "../Tooltip";

const BackLine = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 0.5px);
    height: 1px;
    background-color: ${Colors.grey10PercentBackground};
`;

const List = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    ${({ $normalized }) => $normalized && `width: ${$normalized}%;`}
`;

const Line = styled.div`
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 1.5px);
    border-radius: 0px;
    ${({ $color }) => $color && `background-color: ${$color};`}
`;

const Icon = styled.div`
    display: flex;
    justify-content: start;
    line-height: 1;
    z-index: 1;
`;

const Category = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    position: relative;
    min-width: 20px;
    ${({ $width }) => $width && `width: ${$width};`}

    &:hover ${Line} {
        height: 40%;
        top: 30%;
        border-radius: 6px;
    }
`;

const StyledDayExpenseVisualization = styled.div`
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    position: relative;
    cursor: default;
    min-height: 20px;
`;

const DayExpenseVisualization = ({
    list,
    total,
    normalizedByMaxTotal = 100,
}) => {
    if (!Array.isArray(list) || list.length === 0) {
        return (
            <StyledDayExpenseVisualization>
                <BackLine />
            </StyledDayExpenseVisualization>
        );
    }

    return (
        <StyledDayExpenseVisualization>
            <BackLine />
            <List $normalized={normalizedByMaxTotal}>
                {list.map(({ value, description, iconCode, color }, index) => {
                    const [isTooltipHidden, setIsTooltipHidden] =
                        useState(true);

                    // Calculate length proportion of an expense.
                    // For 100% take total day expenses value.
                    const widthInPerc = (value / total) * 100 + "%";

                    return (
                        <Category
                            key={index}
                            $width={widthInPerc}
                            onMouseEnter={() => setIsTooltipHidden(false)}
                            onMouseLeave={() => setIsTooltipHidden(true)}
                        >
                            <Line $color={color} />
                            <Icon>{String.fromCodePoint(iconCode)}</Icon>
                            <Tooltip isHidden={isTooltipHidden}>
                                {value}
                                {description && `: ${description}`}
                            </Tooltip>
                        </Category>
                    );
                })}
            </List>
        </StyledDayExpenseVisualization>
    );
};

DayExpenseVisualization.propTypes = {
    list: array.isRequired,
    total: number.isRequired,
    normalizedByMaxTotal: number,
};

export default DayExpenseVisualization;
