import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import { bool, node, oneOfType, string } from "prop-types";

const Self = styled.div`
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${Colors.black};
    color: ${Colors.white80};
    max-width: 120px;
    font-size: 14px;
    ${({ $isHidden }) =>
        $isHidden ? `display: none;` : `display: inline-block;`}
    position: absolute;
    left: -8px;
    bottom: calc(100% + 8px);

    &:after {
        content: "";
        position: absolute;
        left: 8px;
        bottom: -14px;
        border-style: solid;
        border-color: ${Colors.black} transparent transparent transparent;
        border-width: 8px;
    }
`;

const Tooltip = ({ children, isHidden = true }) => {
    return <Self $isHidden={isHidden} role="tooltip">{children}</Self>;
};

Tooltip.propTypes = {
    children: oneOfType([string, node]),
    isHidden: bool,
};

export default Tooltip;
